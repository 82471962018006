@import 'components/global.scss';

.date {
  
  position: relative;
  width: fit-content;

  &:last-of-type {

    margin-bottom: 0;

  }

  > div:first-of-type {

    margin-top: -$scale5;

  }
  
  input {

    margin-bottom: 0;
    border-width: 1px;
    border-radius: 0.25rem;
    padding: 0.75rem;
  }

  span {

    width: 2em !important;

  }
}